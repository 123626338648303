/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
function addBlacklistClass() {
	$( 'a' ).each( function() {
		if ( this.href.indexOf('/wp-admin/') !== -1 ||
			 this.href.indexOf('/wp-login.php') !== -1 ) {
			$( this ).addClass( 'wp-link' );
		}
		if ( this.href.indexOf('/shop/') !== -1 ||
			 this.href.indexOf('/wp-login.php') !== -1 ) {
			$( this ).addClass( 'wp-link' );
		}
	});

	$('form').each( function(e) {
		$(this).addClass('wp-link');
	});
}

var settings = {
	anchors: 'a',
	blacklist: '.wp-link',
	onAfter: function($container) {
		addBlacklistClass();
		var $hash = $( window.location.hash );
		if ( $hash.length !== 0 ) {
			var offsetTop = $hash.offset().top;
			$( 'body, html' ).animate( {
					scrollTop: ( offsetTop - 60 ),
				}, {
					duration: 280
			} );
		}
		$container.removeClass( 'slide-out' );

		UTIL.loadEvents

	},
	onStart: {
		duration: 280, // ms
		render: function ( $container ) {
			$container.addClass( 'slide-out' );
		}
	},

};

(function($) {
	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {

			var html = $('html');

			// $( '#main' ).smoothState( settings );

			$('.mobile-search').on('focus', function() {

			$(this).closest('.mobile-search-form').addClass('focus-active');
			});

			$('.mobile-search').on('focusout', function() {
			$(this).closest('.mobile-search-form').removeClass('focus-active');
			});

      var step_slider = $('.step-slider--container__primary');

			step_slider.slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				fade: true,
				dots: true,
				arrows: false,
				asNavFor: '.step-slider--container__image',
        appendDots: $('.step-slider__dots'),
				customPaging: function(slick, index) {
					return '<a data-steps="' + (index) +'">Step ' + (index + 1) + '</a>';
				}
			});

        $('.step-slider__button').click(function() {
          if( $(this).hasClass('prev') ) {
            step_slider.slick('slickPrev');
          }

          if( $(this).hasClass('next') ) {
            step_slider.slick('slickNext');
          }
        });

			function pagebuilderSlider(index) {
				var total = $('*[slideindex="'+ index +'"] .pb_slider-container .slide--item').length;

				if( total > 4) {
					total = 4;
				}

				$('*[slideindex="'+ index +'"] .pb_slider-container').slick({
					slidesToShow: total,
					slidesToScroll: 1,
					arrows: false,
					dots: false,
					responsive: [
						{
							breakpoint: 1024,
								settings: {
									slidesToShow: 3,
									slidesToScroll:1
								}
						},
						{
						breakpoint: 992,
							settings: {
								slidesToShow: 2,
								slidesToScroll:1
							}
						},
						{
							breakpoint: 769,
								settings: {
									slidesToShow: 1,
									slidesToScroll:1
								}
						},
					]
				});
			}


			$('.pb-faq-block--faq-item').on('click', function(e) {
        if($(this).hasClass('active')) {
          $(this).removeClass('active');
        } else {
          $('.pb-faq-block--faq-item.active').removeClass('active');
          $(this).addClass('active');
        }
			});

			$('.slider--wrapper').each( function(key) {
				$(this).attr('slideIndex', key);
				pagebuilderSlider(key);
			});


			$('.pb--slider-container__wrapper').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				fade: true,
				autoplay: true,
				dots: true,
				nextArrow: $('.next-arrow'),
				prevArrow: $('.prev-arrow'),
				customPaging: function (slick, index) {
					return '<div class="slider-icon"></div>';
				},
			});


			$('.testimonial-block--wrapper').slick({
				slidesToScroll: 1,
				slidesToShow: 3,
				prevArrow: $('.testimonial-arrow-prev'),
				nextArrow: $('.testimonial-arrow-next'),
				dots: true,
				customPaging: function (slick, index) {
					return '<div class="slider-icon"></div>';
				},
				responsive: [
					{
						breakpoint: 992,
						settings: {
							slidesToShow: 1,
							autoplay: true,
							fade: true

						}
					}
				]
			});

		$('#rememberme').on('change', function(e) {
			if( $(this).is(':checked') ) {
				$(this).parent().addClass('checked');
			} else {
				$(this).parent().removeClass('checked');
			}
 		});

		$('.step-slider--container__image').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			autoplay: true,
			asNavFor: '.step-slider--container__primary'
		});

		$('.fw-slider--wrapper').slick({
			autoplay: false,
			adaptiveHeight: true,
			prevArrow: $('.prev-arrow'),
			nextArrow: $('.next-arrow')
		});

		$('.header--menu').on('click', function() {
				html.removeClass('loading').toggleClass('nav-open');
		});

		$('.nav-mobile-primary-close--wrapper').on('click', function() {
				html.removeClass('nav-open');
		});

		$('.gform_body input').on('focus', function(e) {
			$(this).parent().prev().addClass('active');
		});
		$('.gform_body input').blur( function(e) {
			if( !$(this).val() > 0 ) {
				$(this).parent().prev().removeClass('active');
			}
		});
		$('.gform_body textarea').on('focus', function(e) {
			$(this).parent().prev().addClass('active');
		});
		$('.gform_body textarea').blur( function(e) {
			if( !$(this).val() > 0 ) {
				$(this).parent().prev().removeClass('active');
			}
		});


		$(document).on('facetwp-loaded', function() {
			$('html, body').animate({ scrollTop: 0 }, 500);
			$('.facetwp-facet').each(function() {
				var $facet = $(this);
				var facet_name = $facet.attr('data-name');
				var facet_label = FWP.settings.labels[facet_name];

				if ($facet.closest('.facet-wrap').length < 1) {
					$facet.wrap('<div class="facet-wrap"></div>');
					$facet.before('<h3 class="facet-label">' + facet_label + '</h3>');
				}
			});
		});

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
				$('iframe[src*="youtube"]').parent().fitVids();
				$('.latest-products--wrapper').slick({
					slidesToShow: 2,
					slidesToScroll: 1,
					autoplay: false,
					animate: "swing",
					nextArrow: $('.latest-prod-arrow-next'),
					prevArrow: $('.latest-prod-arrow-prev'),
					dots: true,
					appendDots: $('.latest-prod-pagination'),
					customPaging: function(slick,index) {
						var index = index + 1
						if( index <= 10) {
							var index = "0".concat(index);
						}
						return '<a>' + (index) + '</a>';
					},
					responsive: [
						{
							breakpoint: 1220,
							settings: {
								slidesToShow: 1
							}
						}
					]
				});
				$('#menu-mobile-menu .menu-item-has-children').on('click', function(e){
					e.preventDefault();
					$(this).toggleClass('active');
				})

				$('#searchform').on('keypress', function(e) {
					if(e.which == 13) {
						$('#searchform > input[type="submit"]').click();
					}
				});
				$('.mobile-searchbox-input').on('keypress', function(e) {
					if(e.which == 13) {
						if( $(this).val().length > 0 ){
							$('.mobile-searchbox-input').submit();
						}
					}
				});


				function triggerActive() {
					$('.product-grid--container').toggleClass('active');
				}

				//$('.product-grid-singular').matchHeight({
				//	byRow: true
				//});

				$(document).on('added_to_cart', function() {
					setTimeout(triggerActive, 1000);
					setTimeout(triggerActive, 2500);
				});

				$(document).on('facetwp-refresh', function() {
					triggerActive();
					$.fn.matchHeight._update();
				});

				$(document).on('facetwp-loaded', function() {

					triggerActive();

					var elementList = document.querySelectorAll('.facet-wrap');
					for (i = 0; i < elementList.length; ++i) {
						var x = elementList[i];
						var n = x.querySelector('.facetwp-facet')
						if(n.hasChildNodes() === false) {
							x.className += ' d-none';
						}
					  }
					  $.fn.matchHeight._update();

				});

				$('.filter-btn-toggle').on('click', function(e){
					e.preventDefault();
					$(this).parent().toggleClass('active');
				});

				$('.mobile-searchbox-input').on('keypress', function() {

				})



				// var el = document.querySelector('.filter-btn-toggle');


				// el.addEventListener("click", function(e)  {

				// });

				// $('.wordpress-mejs-video').fitVids();
				$('.inner-product .media-content img').matchHeight();
				$('.slider-container').slick({
					infinite: true,
					fade: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					cssEase: 'linear',
					arrows: true,
					appendDots: $('.dots-container .inner-dots'),
					dots: true,
					nextArrow: $('.arrow-next'),
					prevArrow: $('.arrow-prev'),
					customPaging: function (slick, index) {
						return '<div class="slider-icon"></div>';
					},

				});


				$('.close-self').on('click', function(){
					$('.close-self').parents('.top-cta').slideUp();
				});

				$('.cart-remove-link').on('click', function(e) {
					e.preventDefault();
					var parent = $(this).parent('.item');
					var key = $(this).data('product-key');
						$.ajax({
							url: 'wp-admin/admin-ajax.php',
							type: "POST",
							dataType: 'json',
							data: {
								'action' : 'remove_item_from_cart',
								'key' : key
							},
							success: function(data) {
								var x = $.parseJSON(data);
								$(parent).hide('slow');

								$('.item-count').text((x - 1) +'');
							}
						});
				});

				$('.gfield_select').select2({
					width: '100%',
					minimumResultsForSearch: Infinity
				});

				$('.trust-slider--container').slick({
					infinite: true,
					slidesToShow: 3,
					slidesToScroll: 1,
					arrows: false,
					dots: false,
					responsive: [{
						breakpoint: 992,
						settings: {
						  slidesToShow: 1,
						}
          			}],
        		});

				$(document).bind('gform_confirmation_loaded', function(e){
					setTimeout(function() {
						$('.product-enquiry').removeClass('active');
						$('.background-blocker').removeClass('active');
					}, 2000);
				});

				$('.enq-toggle').click(function(e) {
					$('.product-enquiry').toggleClass('active');
					$('.background-blocker').toggleClass('active');
				});

				$('.background-blocker').click(function(e) {
					$('.product-enquiry').removeClass('active');
					$('.background-blocker').removeClass('active');
				});

				 $('.login-trigger').on('click', function(e){

				 		$(this).parent().addClass('active');
				 		$('.login-child').addClass('active');
				 });

				$('.close-signup').on('click', function(e) {
					$(this).parent().removeClass('active');
				$('.black-out.login').removeClass('active');
				});

				$('.timeline-container').slick({
					slidesToShow: 4.5,
					slidesToScroll: 1,
					infinite: false,
					arrows: true,
					nextArrow: $('.next'),
					prevArrow: $('.prev'),
					dots: false,
					responsive: [{
						breakpoint: 1200,
						settings: {
						  slidesToShow: 3,
						}
					  }, {
						breakpoint: 992,
						settings: {
						  slidesToShow: 2,
						}
					}, {
						breakpoint: 786,
						settings: {
							slidesToShow: 1
						}
					  }]
				});

				$('.timeline-container').on('setPosition', function(event, slick) {
					var slideCount = slick.slideCount;
					if( (slideCount - 4) <= 1 ) {
						slideCount = 2
					} else {
						slideCount = slideCount;
					}
					var width = ($('.timeline-pagination').width() ) / slideCount;
					$('.indicator').css('width', width );
				});

				$('.timeline-container').on('afterChange', function(event, slick, currentSlide){
					var slideCount = slick.slideCount;

					if( (slideCount - 4) <= 1 ) {
						slideCount = 2;
					} else {
						slideCount = slideCount;
					}

					var pos = ( $('.timeline-pagination').width() ) / slideCount;
					if(currentSlide > 0 ) {

						var currentPos = pos * currentSlide;
					} else {
						var currentPos = 0;
					}
					// $('.indicator').css('left', currentPos);
					$('.indicator').animate({
						left: currentPos,
						opacity: 1
					  }, 500 );

				});

				$('.add_to_cart_ajax').on('click', function(e){
					e.preventDefault();
					var product_id = $(this).val();
					var itemParent = $(this).closest('.add_to_cart--container');
					var product_qty = $(itemParent).find('input[type="number"]').val();
          			var el = $(this);

					var data = {
						action: 'woocommerce_ajax_add_to_cart',
						product_id: product_id,
						product_sku: '',
						quantity: product_qty,
						variation_id: '0',
					};

					$.ajax({
							url: wc_add_to_cart_params.ajax_url,
							type: "POST",
							data: data,
							success: function(data) {
								$('span.item-count').replaceWith(data.fragments["span.item-count"]);
								$(itemParent).find('.view-cart_wrap').addClass('active');
							},
							error: function(err) {
								// console.log(err);
							}
						});

				});

				$('#linked_products_select').on('change', function () {
					var url = $(this).val(); // get selected value
					if (url) { // require a URL
						window.location = url; // redirect
					}
					return false;
				});

				$('.mejs__player').mediaelementplayer({
					pluginPath: "/path/to/shims/",
					success: function(mediaElement, originalNode, instance) {
					}
				});

				// $('.mejs-target').mediaelementplayer({
				// 	videoWidth: '100%',
				// 	videoHeight: '100%',
				// 	enableAutosize: true,
				// });

				// $('.button.increment').on('click', function(e){
				// 	e.preventDefault();
				// 	if( $(this).hasClass('minus') ) {
				// 		var x = parseInt( $(this).siblings('input.qty').val() - 1);
				// 		$(this).siblings('input.qty').val(x);
				// 	}
				// 	if( $(this).hasClass('plus') ) {
				// 		var x = parseInt($(this).siblings('input.qty').val());
				// 		x++;
				// 		$(this).siblings('input.qty').val(x);
				// 	}
				// })
				// $('.cross-sells .products').slick({
				// 	slidesToShow: 3,
				// 	slidesToScroll: 1,
				// 	autoplay: false,
				// })

				$('.slider-single').slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					fade: false,
					adaptiveHeight: true,
					infinite: false,
				   	useTransform: true,
					speed: 400,
					cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
					responsive: [{
						breakpoint: 1024,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							autoplay: true
						}
					}]
				});

				$('.slider-nav')
					.on('init', function(event, slick) {
						$('.slider-nav .slick-slide.slick-current').addClass('is-active');
					})
					.slick({
						slidesToShow: 4,
						slidesToScroll: 4,
						dots: false,
						arrows: false,
						focusOnSelect: false,
						infinite: false,
						responsive: [{
							breakpoint: 1024,
							settings: {
								slidesToShow: 4,
								slidesToScroll: 4,
							}
						}, {
							breakpoint: 640,
							settings: {
								slidesToShow: 4,
								slidesToScroll: 4,
						   }
						}, {
							breakpoint: 420,
							settings: {
								slidesToShow: 0,
								slidesToScroll: 0,
					   }
						}]
					});

				$('.slider-single').on('afterChange', function(event, slick, currentSlide) {
					$('.slider-nav').slick('slickGoTo', currentSlide);
					var currrentNavSlideElem = '.slider-nav .slick-slide[data-slick-index="' + currentSlide + '"]';
					$('.slider-nav .slick-slide.is-active').removeClass('is-active');
					$(currrentNavSlideElem).addClass('is-active');
				});

				$('.slider-nav').on('click', '.slick-slide', function(event) {
					event.preventDefault();
					var goToSingleSlide = $(this).data('slick-index');

					$('.slider-single').slick('slickGoTo', goToSingleSlide);
				});

				$('.recommended-products-slider').slick({
					slidesToScroll: 1,
					slidesToShow: 4,
					arrows: false,
					dots: false,
					responsive: [
						{
							breakpoint: 1400,
							settings: {
								slidesToShow: 3
							}
						},
						{
							breakpoint: 1024,
							settings: {
								slidesToShow: 2
							}
						},
						{
							breakpoint: 786,
							settings: {
								slidesToShow: 1
							}
						},
						{
							breakpoint: 486,
							settings: {
								slidesToShow: 1
							}
						},
					]
				});

				$('.kids-slider').slick({
					arrows: false,
					autoplay: true,
					dots: false,
					nextArrow: $('.kids-next'),
					prevArrow: $('.kids-prev'),
				});



			}
		},
		'home': {
			init: function() {

			},
			finalize: function() {
				$('#front-page-video').mediaelementplayer({
					loop: true,
					autoplay: true
				});
				setTimeout( function(e){
					// $('#sbi_images').slick();
				}, 3000);
				// $('#front-page-video').fitVids();

				var mixer_tips_wrapper = mixitup('.home-tips-tricks', {
					pagination: {
						limit: 6
					}
				});
			}
		},
    'post_type_archive_product': {
      init: function() {
         $(document).on('facetwp-refresh', function() {
           var filters = FWP.facets.menu;

           if(filters.length > 0) {
             var text = 'Shop / ' + filters.join(" / ");
           } else {
             var text = "Shop";
           }
           // Retrieve shop title value
           var title_el = $('.shop-title');

           if(title_el.length) {
             title_el.text(text);
           }

         });

      }
    },
		'blog': {
			init: function() {},

			finalize: function() {

				var hash = window.location.hash.replace(/^#/g, '');
				if( hash ) {
					var initalLoad = '.category-'+hash;
				} else {
					var initalLoad = 'all' ;
				}

				console.log(initalLoad);

				var mixer_blog_wrapper = mixitup('.blog--wrapper', {
					load: {
						filter: initalLoad
					},
					pagination: {
						limit: 6
					},
					templates: {
						pager:
						'<button type="button" class="${classNames} mixitup-number" data-page="${pageNumber}">${pageNumber}</button>',
						pagerNext: '<button type="button" class="${classNames} next" data-page="next"><i class="fal fa-long-arrow-right"></i></button>',
						pagerPrev: '<button type="button" class="${classNames} prev" data-page="prev"><i class="fal fa-long-arrow-left"></i></button>'
					}
				});
			}
		},
		'page_template_template_locate_a_dealer': {
			init: function(){
			},
			finalize: function() {
				if( typeof region ){

					if( region == "usa" ) {
					// $('#aus-map').hide();
					// $('#usa-map').show();
				} else {
					// $('#usa-map').hide();
					// $('#aus-map').show();

					}
				}
			}
 		},
		// Home page
		'page_template_template_frequently_asked_questions': {
			init: function() {

				$('.faq-post__trigger').click(function(e) {
					e.preventDefault();
					var $this = $(this);

					$this.parent().toggleClass('active').find('.faq-post__bottom').slideToggle();
				});

			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS

				var mixer_faqs_wrapper = mixitup('.faqs__wrapper');
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		},
		'single_product' : {
			finalize: function() {
				$('.enquire-trigger').on('click', function(e) {
					$('body').addClass('enquire-active');
				});

				$('.block-element').on('click', function(e) {
					$('body').toggleClass('enquire-active');
				});

				$('.close-element').on('click', function(e) {
					$('body').toggleClass('enquire-active');
				});

				// Woocommerce variation product event listener
				$( '.single_variation_wrap' ).on( 'show_variation', function( event, variation ) {
					console.log( variation );
					$('.sticky-product-nav__price').html(variation.price_html);
				});		
			}
		},
		'post_type_archive_product': {
			finalize: function() {
				$('.enquire-trigger').on('click', function(e) {
					$('body').addClass('enquire-active');
				});

				$('.block-element').on('click', function(e) {
					$('body').toggleClass('enquire-active');
				});

				$('.close-element').on('click', function(e) {
					$('body').toggleClass('enquire-active');
				});
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
